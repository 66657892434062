import { Injectable } from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { Action } from '@ui/action';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

import { NotificationConfig, NotificationPosition } from './notification.types';

export const DEFAULT_OPTIONS: Partial<IndividualConfig> = {
  progressBar: true,
  positionClass: NotificationPosition.BottomCenter,
  timeOut: 2_500,
};

@Injectable()
export class NotificationService {
  public constructor(private toastr: ToastrService) {}

  public notifyError(
    message: string,
    title?: string,
    options?: Partial<NotificationConfig>,
  ): void {
    const toast = this.toastr.error(message, title, this.getOptions(options));
    toast.portal.instance.actions = this.getActions(toast, options);
  }

  public notifySuccess(
    message: string,
    title?: string,
    options?: Partial<NotificationConfig>,
  ): void {
    const toast = this.toastr.success(message, title, this.getOptions(options));
    toast.portal.instance.actions = this.getActions(toast, options);
  }

  public notifyWarning(
    message: string,
    title?: string,
    options?: Partial<NotificationConfig>,
  ): void {
    const toast = this.toastr.warning(message, title, this.getOptions(options));
    toast.portal.instance.actions = this.getActions(toast, options);
  }

  public notifyInfo(
    message: string,
    title?: string,
    options?: Partial<NotificationConfig>,
  ): void {
    const toast = this.toastr.info(message, title, this.getOptions(options));
    toast.portal.instance.actions = this.getActions(toast, options);
  }

  private getOptions(
    options?: Partial<NotificationConfig>,
  ): Partial<IndividualConfig> {
    return {
      ...DEFAULT_OPTIONS,
      disableTimeOut: isNotNil(options?.actions),
      positionClass: options?.position ?? DEFAULT_OPTIONS.positionClass,
      timeOut: options?.timeOut ?? DEFAULT_OPTIONS.timeOut,
    };
  }

  private getActions(
    toast: ActiveToast<any>,
    options?: Partial<NotificationConfig>,
  ): Action[] | Nil {
    return options?.actions?.map((action) => {
      return {
        ...action,
        callback: () => {
          if (isNotNil(action.callback)) {
            action.callback();
          }
          if (action.closeOnCallBack) {
            toast.toastRef.close();
          }
        },
      };
    });
  }
}
