import { Action } from '../action';

export interface NotificationAction extends Action {
  closeOnCallBack?: boolean;
}

export enum NotificationPosition {
  TopLeft = 'toast-top-left',
  TopCenter = 'toast-top-center',
  TopRight = 'toast-top-right',
  BottomLeft = 'toast-bottom-left',
  BottomCenter = 'toast-bottom-center',
  BottomRight = 'toast-bottom-right',
}

export interface NotificationConfig {
  position: NotificationPosition;
  actions: NotificationAction[];
  timeOut: number;
}
